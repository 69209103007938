import './Footer.css'
import Redirects from "@/commons/utils/Redirects"
import {environment} from "@/commons/configs/Config";

const Footer = () => {
    let currentYear = () => {
        return new Date().getFullYear()
    }
    return (
        <>
            <div className={"footer___Background"}>
                <div className={"footer__content"}>
                    <div className={"footer___box"}>
                            <span className={"footer___title"}>
                                Chi Siamo
                            </span>
                        <span className={"footer___text"}>
                                EBLCraft nasce l'8 dicembre 2019 dall'idea di ErenBlaze e le richieste dei suoi iscritti.<br/>
                                L'idea iniziale era quella di dare uno spazio di ritrovo e di creatività interna alla community,
                                con la possibilità di realizzare un server pieno di build e città, una cosa che lo ha sempre affascinato.<br/>
                            </span>
                    </div>
                    <div className={"footer___box"}>
                        <span className={"footer___title"}>Link Utili</span>
                        <span className={"footer___text"}>
                                <span className={"footer___links"} onClick={(e) => {
                                    Redirects.send("https://store.eblcraft.it")
                                }}>Store</span>
                                <span className={"footer___links"} onClick={(e) => {
                                    Redirects.send("https://discord.erenblaze.it")
                                }}>Discord</span>
                                <span className={"footer___links"} onClick={(e) => {
                                    Redirects.send("/social")
                                }}>Social</span>
                            </span>
                    </div>
                    <div className={"footer___box"}>
                        <span className={"footer___title"}>Supportaci</span>
                        <span className={"footer___text"}>
                                Vuoi supportare il server? <br/>
                                Acquistando un pacchetto sul nostro store oltre a supportarci avrai dei vantaggi in game!<br/>
                            </span>

                        <button className={"footer___button"} onClick={(e) => {
                            Redirects.send("https://store.eblcraft.it", e)
                        }}>Store
                        </button>
                    </div>
                </div>
            </div>
            <div className="footer__legal_content">
                <div className="footer__legal_content_left">
                    <div className="general_title">Informazioni</div>
                    <div className="general_text blue_color">Il server EBLCraft non è in alcun modo affiliato con Mojang
                        Studios.<br/>
                        Ogni contributo o acquisto fatto sullo store andrà interamente al team EBLCraft.<br/>
                        Per qualsiasi tipo di assistenza potete aprire un ticket sul nostro <a onClick={(e) => {
                            Redirects.send(environment.reportsUrl + "", e)
                        }}>sito report</a>
                    </div>
                </div>
                <div className="footer__legal_content_right">
                    <div className="general_title">Termini di Servizio</div>
                    <div className="general_text blue_color">Tutti i termini di servizio possono essere consultati
                        alla <a onClick={(e) => {
                            Redirects.send("/tos", e)
                        }}>pagina dedicata</a></div>
                </div>

                <div className={"footer___box_centered"}>
                    <span className={"footer___copyright"}>© EBLCraft 2019-{currentYear()}</span>
                </div>
            </div>
        </>
    );
}

export default Footer;