import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import React from "react";
import Redirect from "@/commons/utils/Components/Redirect";
import Home from "@/reports/pages/home/Home";
import UserReports from "@/reports/pages/reports/UserReports";
import Post from "@/reports/pages/post/Post";
import Tickets from "@/reports/pages/tickets/Tickets";

const ReportsApp: React.FC = () => {
    let path = window.location.pathname;
    let loadAll = false;

    if (!path.toLocaleLowerCase().includes("error")) {
        localStorage.setItem("errorName", "pageerror");
    }
    if (!path.toLocaleLowerCase().startsWith("/file")) {
        loadAll = true;
    }

    const appContent = createBrowserRouter(
        [{
            path: '/', element: <Navigate to={"/reports"} replace/>
        }, {
            path: '/profile', element: <Redirect domain={"www"} to={"profile"}/>
        }, {
            path: '/faq', element: <Redirect domain={"www"} to={"faq"}/>
        }, {
            path: '/post/:id', element: <Post/>
        }, {
            path: '/tickets', element: <Tickets/>
        }, {
            path: '/home', element: <Redirect domain={"www"}/>
        }, {
            path: '/report', element: <Navigate to={"/reports"} replace/>
        }, {
            path: '/reports', element: <UserReports/>
        }, {
            path: '/admin', element: <Redirect domain={"admin"}/>
        }]
    )
    return (
        <RouterProvider router={appContent}/>
    )
}

export default ReportsApp;