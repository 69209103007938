import React, {useEffect} from 'react';
import styles from './Background.module.css'

const Background = () => {
    let currentImage = -1;
    let images = ["http://static.eblcraft.it/site/img/bg_1.webp",
        "http://static.eblcraft.it/site/img/bg_2.webp",
        "http://static.eblcraft.it/site/img/bg_3.webp",
        "http://static.eblcraft.it/site/img/bg_4.webp"];

    function loadDivs() {
        for (let i = 0; i < images.length; i++) {
            let div = document.createElement("div");
            div.classList.add(styles.background__div);
            div.classList.add(styles.background__invisible);
            div.id = "home_bg_" + i;
            div.style.backgroundImage = "url(" + images[i] + ")";
            (document.getElementById("home_background") as HTMLElement).appendChild(div);
        }
        changeImage();
    }

    useEffect(() => {
        setTimeout(loadDivs, 1);
    }, []);

    function changeImage() {
        let baseID = "home_bg_"
        let newImageId = Math.floor(Math.random() * images.length);
        while (newImageId === currentImage) {
            newImageId = Math.floor(Math.random() * images.length);
        }

        if (currentImage !== -1) {
            let oldImage = document.getElementById(baseID + currentImage);
            if (oldImage !== null)
                oldImage.classList.add(styles.background__invisible);
        }


        let newImage = document.getElementById(baseID + newImageId) as HTMLElement;
        if (newImage !== null)
            newImage.classList.remove(styles.background__invisible);

        currentImage = newImageId;
        setTimeout(changeImage, 5000);
    }

    return (
        <div className={styles.background__body} id={"home_background"}></div>
    );

}

export default Background;